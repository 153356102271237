import { defineStore } from 'pinia';
import { getToken } from '@/service/authLogin';
import axios from 'axios';
export const recoveredOrdersData = defineStore('recoveredOrders', {
  state: () => ({
    token: getToken(),
    data: null,
    route: process.env.VUE_APP_API,
  }),
  actions: {
    async getReportsData(parameters) {
      try {
        const response = await axios.get(`${this.route}/api/ordersReport`, {
          headers: this.token,
          params: {
            dt_params: JSON.stringify({ ...parameters }),
          },
        });
        return response.data.original;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async getDifference(email, coupon) {
      try {
        const response = await axios.get(`${this.route}/api/orderLineItems/`, {
          headers: this.token,
          params: {
            email,
            coupon,
          },
        });
        return response.data.original;
      } catch (error) {
        console.error(error);
        return null;
      }
    },


  },
});
