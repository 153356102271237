<template>
  <button>x</button>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/helper/main';
button {
  background-color: transparent;
  border: 1px solid $inputs-border-color;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 1.5rem;
  color: red;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  transition: all 0.5s;
  &:hover {
    color: $white;
    background: red;
  }
}
</style>
