<template>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700;800&display=swap"
    rel="stylesheet"
  />
  <div class="container" v-if="statusApp">
    <main-wrapper :routeName="routeName" ></main-wrapper>
    <router-view @routeName="handleRouteName"></router-view>
  </div>
  <div class="loading" v-else><i class="pi pi-spin pi-spinner"></i></div>
</template>

<script>
import MainWrapper from './components/MainWrapper.vue';
import { authLogin } from './service/authLogin';
import { useRouter } from 'vue-router';
import 'primevue/resources/themes/lara-light-indigo/theme.css';

//core
import 'primevue/resources/primevue.min.css';

export default {
  name: 'App',
  components: { MainWrapper },
  data() {
    return {
      router: useRouter(),
      statusApp: false,
      routeName: '',
    };
  },
  methods: {
    handleRouteName(name) {
      this.routeName = name;
    },
  },
  emits: ['routeName'],

  beforeCreate() {
    const auth = new authLogin();
    auth.startApp()?.then(data => {
      if (data && !data?.meta.redirect) {
        this.statusApp = true;
        this.router.push('/');
      }
    });
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/global.scss';
.loading {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 5rem;
    color: $interactive;
  }
}
</style>
