import { defineStore } from 'pinia';
import { getToken } from '@/service/authLogin';
import axios from 'axios';

export const emailTemplate = defineStore('emailTemplate', {
  state: () => ({
    token: getToken(),
    route: process.env.VUE_APP_API,
  }),
  actions: {
    //preview email template
    async getEmailPreview(emailTemplate) {
      try {
        const response = await axios.post(
          `${this.route}/api/MailBlade`,
          {
            emailTemplate: emailTemplate,
          },
          {
            headers: this.token,
          }
        );
        return response;
      } catch (error) {
        console.error(error);
        return null;
      }
    },

    //show the default email template
    async showDefaultEmailTemplate() {
      try {
        const response = await axios.get(`${this.route}/api/showTemplate`, {
          headers: this.token,
        });
        return response.data.original.mail_template[0];
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async saveNewEmailTemplate(newEmailTemplate) {
      //update email template with the new one
      try {
        const response = await axios.post(`${this.route}/api/updateTemplate`, newEmailTemplate, {
          headers: this.token,
        });
        return response.data.original;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    // reset the email template
    async resetEmailTemplate() {
      try {
        const response = await axios.get(`${this.route}/api/resetTemplate`, {
          headers: this.token,
        });
        return response.data.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async getLogs() {
      try {
        const response = await axios.get(`https://recovercart-api.numinix.com/api/logs/`, {
          headers: this.token,
        });
        console.log(response.data.payload);
        return response;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
  },
});


