export default function formatTimeValue(timeValue, timeUnit) {
  let toString = timeUnit.toLowerCase();
  if (toString && timeValue) {
    if (toString === 'second') {
      let minutes = Math.floor(timeValue / 60);
      let remainingSeconds = timeValue % 60;
      let parts = [
        minutes && `${minutes} minute${minutes === 1 ? '' : 's'}`,
        remainingSeconds && `${remainingSeconds} second${remainingSeconds === 1 ? '' : 's'}`,
      ];
      {
        if (remainingSeconds === 0) {
          return `${parts.slice(0, -1)}`;
        }

        if (minutes === 0) {
          return `${parts.slice(-1)}`;
        } else {
          return `${parts.slice(0, -1).join(', ')} and  ${parts.slice(-1)}`;
        }
      }
    }
    if (toString === 'minute') {
      let hours = Math.floor(timeValue / 60);
      let remainingMinutes = timeValue % 60;

      let parts = [
        hours && `${hours} hour${hours === 1 ? '' : 's'}`,
        remainingMinutes && `${remainingMinutes} minute${remainingMinutes === 1 ? '' : 's'}`,
      ];
      {
        if (remainingMinutes === 0) {
          return `${parts.slice(0, -1)}`;
        }

        if (hours === 0) {
          return `${parts.slice(-1)}`;
        } else {
          return `${parts.slice(0, -1).join(', ')} and  ${parts.slice(-1)}`;
        }
      }
    }
    if (toString === 'hour') {
      let days = Math.floor(timeValue / 24);
      let remainingHours = timeValue % 24;

      let parts = [
        days && `${days} day${days === 1 ? '' : 's'}`,
        remainingHours && `${remainingHours} hour${remainingHours === 1 ? '' : 's'}`,
      ];
      {
        if (remainingHours === 0) {
          return `${parts.slice(0, -1)}`;
        }

        if (days === 0) {
          return `${parts.slice(-1)}`;
        } else {
          return `${parts.slice(0, -1).join(', ')} and  ${parts.slice(-1)}`;
        }
      }
    }
    if (toString === 'day') {
      let month = Math.floor(timeValue / 30);
      let remainingDays = timeValue % 30;
      let parts = [
        month && `${month} month${month === 1 ? '' : 's'}`,
        remainingDays && `${remainingDays} day${remainingDays === 1 ? '' : 's'}`,
      ];
      {
        if (remainingDays === 0) {
          return `${parts.slice(0, -1)}`;
        }

        if (month === 0) {
          return `${parts.slice(-1)}`;
        } else {
          return `${parts.slice(0, -1).join(', ')} and  ${parts.slice(-1)}`;
        }
      }
    }
    if (toString === 'month') {
      let year = Math.floor(timeValue / 12);
      let remainingMonths = timeValue % 12;
      let parts = [
        year && `${year} year${year === 1 ? '' : 's'}`,
        remainingMonths && `${remainingMonths} month${remainingMonths === 1 ? '' : 's'}`,
      ];
      {
        if (remainingMonths === 0) {
          return `${parts.slice(0, -1)}`;
        }

        if (year === 0) {
          return `${parts.slice(-1)}`;
        } else {
          return `${parts.slice(0, -1).join(', ')} and  ${parts.slice(-1)}`;
        }
      }
    }
    if (toString === 'year') {
      return timeValue + timeValue && `${timeValue} year${timeValue === 1 ? '' : 's'}`;
    }
  } else return '';
}
