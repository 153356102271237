export class authLogin {
  headersV = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem(process.env.VUE_APP_SESSTION_NAME)}`,
  };

  urlParams = new URLSearchParams(window.location.search);
  session_token = this.urlParams.get('session_token');
  object = {
    session_token: this.urlParams.get('session_token'),
  };
  clientName = '';
  constructor() { }

  startApp() {
    if (this.urlParams.has('code')) {
      this.fetchToCode();
    }
    // start working login auth
    if (this.urlParams.has('widgets')) {
      // if login widgets in main app this is way
      this.loginWidgets();
    } else {
      // if login in main app this is way
      return this.login();
    }
  }

  // Start  Login widgets
  loginWidgets() {
    return fetch(`${process.env.VUE_APP_API}/api/auth/login`, {
      method: 'post',
      headers: this.this.headersV,
      body: JSON.stringify(this.object),
    })
      .then(data => {
        return data.json();
      })
      .then(data => {
        sessionStorage.setItem(process.env.VUE_APP_SESSTION_NAME, data.meta.token);
        if (!data.meta.redirect) {
          let product_widget = {
            product_id: this.urlParams.get('pid'),
            variant_id: this.urlParams.get('vid'),
            product_widget: new URLSearchParams(window.location.search).has('widgets'),
          };

          window.location.replace(`/widgets?product_widget=${encodeURIComponent(JSON.stringify(product_widget))}`);
        } else {
          let product_widget = {
            product_widget: false,
          };
          window.location.replace(`/widgets?product_widget=${encodeURIComponent(JSON.stringify(product_widget))}`);
        }
      })
      .catch(data => {
        console.log(data);
      });
  }

  login() {
    // post session token
    if (this.urlParams.has('session_token'))
      return fetch(`${process.env.VUE_APP_API}/api/auth/login`, {
        method: 'post',
        headers: this.headersV,
        body: JSON.stringify(this.object),
      })
        .then(data => {
          return data.json();
        })
        .then(data => {
          sessionStorage.setItem(process.env.VUE_APP_SESSTION_NAME, data.meta.token);
          if (data.meta.redirect) {
            window.parent.location.href = data.meta.authUrl;
          } else {
            this.clientName = data.data.name;
            return data;
          }
        })
        .catch(data => {
          console.log(data);
        });
    else if (this.urlParams.has('deploy.php')) console.log('fd');
    else {
      if (
        sessionStorage.getItem(process.env.VUE_APP_SESSTION_NAME) === null &&
        process.env.VUE_APP_REDIRECT_WHEN_OPEN_OUT_ADMIN === 'true'
      ) {
        window.parent.location.href = process.env.VUE_APP_REDIRCT_TO;
      }
    }
  }

  fetchToCode() {
    // eslint-disable-next-line no-unused-vars
    let objectCode = {
      code: this.urlParams.get('code'),
      scope: this.urlParams.get('scope'),
      state: this.urlParams.get('state'),
    };
    fetch(`${process.env.VUE_APP_API}/api/auth/code`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem(process.env.VUE_APP_SESSTION_NAME)}`,
      },
      body: JSON.stringify(objectCode),
    })
      .then(data => {
        return data.json();
      })
      .then(data => {
        if (data.acknowledge) {
          window.location.href = process.env.VUE_APP_REDIRCT_TO;
        }
      })
      .catch(data => {
        console.log(data);
      });
  }
}

export function getToken() {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem(process.env.VUE_APP_SESSTION_NAME)}`,
  };
}
