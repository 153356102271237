<template>
  <div class="container">
    <nav>
      <!-- Burger ICON TH HANDEL NAVBAR IN SMALL SCREENS -->
      <div class="navbar-header">
        <button
          type="button"
          class="navbar-toggle"
          @click="toggleIconHandler"
          ref="icon"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>
      <ul ref="ul" v-if="!showDropdown">
        <template v-for="link in navLinks" :key="link.path">
          <li>
            <router-link :to="link.path">
              <span> </span><span>{{ link.text }}</span>
            </router-link>
          </li>
        </template>
      </ul>
    </nav>
  </div>
</template>

<script>
/* eslint-disable */
import { emailConfiguration } from "@/stores/configuration";
import WarningMessage from "../ui/WarningMessage.vue";
import { mainSettings } from "@/stores/settings";

export default {
  components: { WarningMessage },
  name: "SiteBar",
  data() {
    return {
      showDropdown: false,
      emailStore: emailConfiguration(),
      settingStore: mainSettings(),
      navLinks: [
        { path: "/Reports", text: "Reports" },
        { path: "/Recovered-Orders", text: "Recovered Orders" },
        { path: "/SentOrders", text: "Sent Orders" },
        { path: "/Email-Template", text: "Email Template" },
        { path: "/Configuration", text: "Configuration" },
        { path: "/Logs", text: "Logs" },
        { path: "/Settings", text: "Settings" },
      ],
      configWarning: "",
      emailWarning: "",
      configWarningMessage: "you should set the of the email configuration ",
      emailWarningMessage: "you should set the settings email ",
    };
  },
  async mounted() {
    this.icon = this.$refs.icon;
    this.configWarning = await this.emailStore.getConfiguration();
    this.emailWarning = await this.settingStore.getDefaultSettings();
  },

  methods: {
    //FN TO HANDEL BURGER ICON
    toggleIconHandler() {
      this.showDropdown = !this.showDropdown;
      this.$refs.icon.classList.toggle("close");
      if (this.ul) {
        this.ul.classList.toggle("show");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/helper/variables";
@import "@/assets/sass/helper/mixin";

nav {
  width: 100%;
  background-color: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px 10px 13px -2px #6a73790d;
  border-bottom: 1px solid #edeeef;
  border-top: 1px solid #edeeef;
  font-weight: 400;
  padding: 0px 50px;
  color: #6d6d6d;
  @include mediaQ(xsm) {
    padding: 5px 10px;
    font-size: 10px;
    line-height: 16px;
    flex-wrap: wrap;
  }
  @include mediaQ(sm) {
    font-size: 12px;
    line-height: 20px;
    padding: 0px 20px;
  }
  @include mediaQ(lg) {
    font-size: 14px;
    line-height: 24px;
  }
  .navbar-header {
    .navbar-toggle {
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding: 5px;
      flex-direction: column;
      @include mediaQ(xsm) {
        display: flex;
      }
      @include mediaQ(sm) {
        display: flex;
      }
      @include mediaQ(md) {
        display: none;
      }
      width: 100%;
      align-items: flex-end;
      .icon-bar {
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;
        background-color: #6d6d6d;
        margin: 4px 0;
      }
    }
  }
  ul {
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: start;
    gap: 23px;
    padding: 20px 0px 15px 0px;
    /* Show the ul when the burger icon is clicked */

    @include mediaQ(xsm) {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
    }
    @include mediaQ(sm) {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
    }
    @include mediaQ(md) {
      flex-direction: row;
      align-items: center;

      gap: 0;
    }
    li {
      &:last-child {
        @include mediaQ(xsm) {
          margin-left: 0;
        }
        @include mediaQ(sm) {
          margin-left: 0;
        }
        @include mediaQ(md) {
          margin-left: auto;
        }
      }
    }
    a {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 20px 15px;
      @include mediaQ(xsm) {
        padding: 10px 5px;
      }
      @include mediaQ(sm) {
        padding: 15px 8px;
      }
      color: #6d6d6d;
      transition: 0.2s;
      font-weight: 400;
      text-decoration: none;
      border-bottom: 3px solid #09a59b00;
      &:hover {
        color: $border-bottom-line;
        svg path {
          fill: $border-bottom-line;
        }
      }
      .list-icon {
        width: 16.2px;
        height: 16.2px;
        margin-right: 7px;
      }
    }
  }
  .active {
    text-decoration: none;
    color: $main-color;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      height: 3px;
      width: 120%;
      top: 130%;
      background: $border-bottom-line;

      @include mediaQ(xsm) {
        display: none;
      }
      @include mediaQ(sm) {
        display: none;
      }
      @include mediaQ(md) {
        display: block;
      }
    }
    color: $border-bottom-line;
    svg path {
      fill: $border-bottom-line;
    }
  }
}
</style>
