<template>
  <section class="container settings-wrapper">
    <form @submit.prevent="onSubmit" class="parent">
      <div
        v-for="field in fields"
        :key="field.id"
        :class="`div${field.index} input`"
      >
        <label :for="field.id">{{ field.label }}</label>
        <component
          :is="field.component"
          v-model="field.value"
          :id="field.id"
          :type="field.type"
          :toggleMask="field.toggleMask"
          :inputStyle="{ width: '100%' }"
          v-if="field.component !== 'Dropdown'"
        />
        <template v-if="field.error">
          <p class="warning-error">{{ field.errorMessage }}</p>
        </template>

        <Dropdown
          v-model="field.value"
          :options="field.options"
          v-if="field.component === 'Dropdown'"
          showClear
          optionLabel="label"
          :placeholder="`Select ${field.label}`"
        />
      </div>
      <div class="button-wrapper">
        <div class="loader-wrapper" v-if="loading">
          <i class="pi pi-spin pi-spinner"></i>
        </div>
        <button
          :disabled="loading ? true : false"
          class="button-admin button"
          id="btn"
          @click.prevent="onSubmit"
        >
          Update
        </button>
      </div>
    </form>
  </section>
  <template>
    <Toast />
  </template>
</template>

<script>
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Button from "primevue/button";

import InputNumber from "primevue/inputnumber";
import inputsFields from "../SettingsOrders/settingsnputsDara.json";
import Dropdown from "primevue/dropdown";
import Toast from "primevue/toast";

import { mainSettings } from "@/stores/settings";

export default {
  name: "SettingsOrders",
  components: {
    InputText,
    Password,
    InputNumber,
    Dropdown,
    Button,
    Toast,
  },
  data() {
    const fields = inputsFields.fields.map((field) => {
      if (field.id === "discountValue") {
        // Set the suffix based on the value of the discount field
        field.suffix = this.discount === "%" ? "%" : "$";
      }
      return field;
    });
    return {
      fields,
      selectCoupon: inputsFields.selectCoupon,
      optionsOfCoupons: inputsFields.optionsOfCoupons,
      discount: null,
      formData: {},
      store: mainSettings(),
      message: null,
      loading: false,
    };
  },
  methods: {
    updateFormateData() {
      this.fields.forEach((field) => {
        this.formData[field.id] = field.value;
        field.error = false; // reset error messages
        field.errorMessage = "";
      });
    },
    async onSubmit() {
      this.loading = true;
      this.updateFormateData();
      try {
        const data = await this.store.updateNewSettings(this.formData);
        if (data && data.status === "success") {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Your settings have been updated successfully",
            life: 3000,
          });
        } else {
          console.log(data);
          // this.fields.forEach((field) => {
          //   if (errors[field.id]) {
          //     field.error = true;
          //     field.errorMessage = errors[field.id][0];
          //   }
          // });
        }
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "there is a issue in the server please try again",
          life: 3000,
        });
      }

      this.loading = false;
    },
  },
  emits: ["routeName"],
  async mounted() {
    this.$emit("routeName", "Mail Settings");
    const data = await this.store.getDefaultSettings();
    if (data) {
      this.fields.forEach((field) => {
        field.value = data[field.id];
      });
    }
    this.updateFormateData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/helper/main";
@import "@/assets/sass/helper/typography";
@import "@/assets/sass/helper/mixin";

.settings-wrapper {
  background: $box-background-color;
  padding: 36px 27px;
  .parent {
    @include mediaQ(xsm) {
      grid-template-columns: repeat(9, 1fr);
      grid-template-rows: repeat(9, 1fr);
      grid-template-rows: auto;
      grid-row-gap: 16px;
      display: flex;
      flex-direction: column;
    }
    @include mediaQ(sm) {
      grid-template-columns: repeat(9, 1fr);
      grid-template-rows: repeat(9, 1fr);
      grid-template-rows: auto;
      grid-row-gap: 16px;
      display: flex;
      flex-direction: column;
    }
    @include mediaQ(md) {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      grid-template-rows: repeat(5, 1fr);
      grid-column-gap: 14px;
      grid-row-gap: 24px;
      margin-left: 20px;
    }
    @include mediaQ(xlg) {
      grid-template-columns: repeat(9, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }
    .warning-error {
      color: red;
      margin-top: 8px;
    }
    .div1 {
      @include mediaQ(md) {
        grid-area: 1 / 1 / 2 /6;
      }
      @include mediaQ(lg) {
        grid-area: 1 / 1 / 2 / 5;
      }
      @include mediaQ(xlg) {
        grid-area: 1 / 1 / 2 / 4;
      }
    }
    .div2 {
      @include mediaQ(md) {
        grid-area: 1 / 6 / 2 / 10;
      }
      @include mediaQ(lg) {
        grid-area: 1 / 5 / 2 / 9;
      }
      @include mediaQ(xlg) {
        grid-area: 1 / 4 / 2 / 7;
      }
    }
    .div3 {
      @include mediaQ(md) {
        grid-area: 2 / 1 / 3 / 6;
      }

      @include mediaQ(lg) {
        grid-area: 2 / 1 / 2 / 5;
      }
      @include mediaQ(xlg) {
        grid-area: 2 / 1 / 2 / 4;
      }
    }
    .div4 {
      @include mediaQ(md) {
        grid-area: 2 / 6 / 3 / 10;
      }
      @include mediaQ(lg) {
        grid-area: 2 / 5 / 3 / 9;
      }
      @include mediaQ(xlg) {
        grid-area: 2 / 4 / 2 / 7;
      }
    }
    .div5 {
      @include mediaQ(md) {
        grid-area: 3 / 1 / 4 / 6;
      }
      @include mediaQ(lg) {
        grid-area: 3 / 1 / 4 / 5;
      }
      @include mediaQ(xlg) {
        grid-area: 3 / 1 / 4 / 4;
      }
    }
    .div6 {
      @include mediaQ(md) {
        grid-area: 3 / 6 / 5 / 10;
      }

      @include mediaQ(lg) {
        grid-area: 3 / 5 / 4 / 9;
      }
      @include mediaQ(xlg) {
        grid-area: 3 / 4 / 4 / 7;
      }
    }
    .div7 {
      @include mediaQ(md) {
        grid-area: 4 / 1 / 4/ 6;
      }
      @include mediaQ(lg) {
        grid-area: 4 / 1 / 5/ 3;
      }
      @include mediaQ(xlg) {
        grid-area: 4 / 1 / 5 / 4;
      }
    }
    .button-wrapper {
      @include mediaQ(xsm) {
        margin-left: auto;
      }
      @include mediaQ(sm) {
        margin-left: auto;
      }

      @include mediaQ(md) {
        grid-area: 6/ 9 / 6 / 10;
        margin-left: 0;
      }
      @include mediaQ(lg) {
        grid-area: 5 / 8 / 6 / 9;
      }
      @include mediaQ(xlg) {
        grid-area: 5 / 6 / 6 / 7;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 30px;
      }
    }
  }
}
</style>
