<template>
  <upper-nav-vue appName="Recover Cart" />
  <middle-bar :routeName="routeName" />
  <site-bar />
</template>

<script>
import UpperNavVue from './navs/UpperNav.vue';
import SiteBar from './navs/SiteBar.vue';
import MiddleBar from './navs/MiddleBar.vue';
export default {
  components: {
    UpperNavVue,
    SiteBar,
    MiddleBar,
  },
  data() {
    return {
    };
  },
  props: ['routeName'],
};
</script>

<style></style>
