import { createRouter, createWebHistory } from 'vue-router';
import Reports from '../components/reports/ReportsOrders.vue';
import RecoveredOrders from '../components/recoveredOrders/RecoveredOrders.vue';
import EmailTemplate from '../components/emailTemplate/EmailTemplate.vue';
import Configuration from '../components/configurationForm/ConfigurationForm.vue';
import SettingsOrders from '../components/SettingsOrders/SettingsOrders.vue';
import AppLogs from '../components/appLogs/AppLogs.vue';
import SentOrders from "../components/sentOrders/SentOrders.vue"
// import MainWrapper from '../components/MainWrapper.vue';

const routes = [
  { path: '/', redirect: '/Reports' },
  { path: '/Reports', component: Reports },
  { path: '/SentOrders', component: SentOrders },
  { path: '/Recovered-Orders', component: RecoveredOrders },
  { path: '/Email-Template', component: EmailTemplate },
  { path: '/Configuration', component: Configuration },
  { path: '/Settings', component: SettingsOrders },
  { path: '/Logs', component: AppLogs },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
  linkActiveClass: 'active',
});

export default router;
