<template>
  <div class="reports-main-wrapper container">
    <h3>Summary</h3>
    <VueDatePicker
      v-model="date"
      range
      multi-calendars
      placeholder="Select Date"
      :multi-static="false"
      :format="format"
      class="datepicker"
      @update:model-value="handleDate"
      @cleared="clearDate"
    />
    <div class="section-wrapper">
      <template v-if="dataOfBoxes?.hasOwnProperty('errors')">
        <template v-for="(error, index) in dataOfBoxes.errors" :key="index">
          <BoxOfStaticsVue
            :errors="error.message"
            :loadingRequest="staticesLoading"
          />
        </template>
      </template>
      <template v-else>
        <template v-for="(value, key) in dataOfBoxes" :key="key">
          <BoxOfStaticsVue
            :boxCounter="formatValueAsCurrency(value, key)"
            :boxContent="convertSnakeToCamel(key)"
            :loadingRequest="staticesLoading"
          />
        </template>
      </template>
    </div>

    <h6>Orders</h6>
    <div class="section-wrapper container">
      <static-table
        :tableInfo="sentDataTable"
        class="table"
        :info="true"
        titleTable="Sent Orders"
        @fetchTableData="fetchTableData"
        :loading="sentOrdersLoading"
        :headers="headerDataRef"
      />
      <static-table
        :tableInfo="recoveredDataTable"
        class="table"
        :info="true"
        titleTable="Recovered Orders"
        @fetchTableData="fetchTableData"
        :loading="recoveredOrdersLoading"
        :headers="headerDataRef"
      />
    </div>
  </div>
  <Toast />
</template>

<script>
import BoxOfStaticsVue from "../ui/BoxOfStatics.vue";
import StaticTable from "../tables/StaticTable.vue";
import { reportsData } from "../../stores/reportsStore";
import sentAndReceivedHeaders from "./tableHeaders.json";
import Toast from "primevue/toast";
import emitter from "@/service/mitt";
import formatMoney from "@/helpers/functions/formatMoney";

export default {
  name: "ReportsOrders",
  components: {
    BoxOfStaticsVue,
    StaticTable,
    Toast,
  },
  data() {
    return {
      dataOfBoxes: [],
      store: reportsData(),
      sentDataTable: null,
      recoveredDataTable: null,
      date: [],
      format: "MM/dd/yyyy",
      recoveredOrdersLoading: false,
      sentOrdersLoading: false,
      staticesLoading: false,
      headerDataRef: sentAndReceivedHeaders,
    };
  },
  emits: ["routeName"],
  methods: {
    // fn to convert the time from datepicker to time stamp
    convertDataToTimeStamp(date) {
      return {
        from: Math.floor(Date.parse(date[0]) / 1000),
        to: Math.floor(Date.parse(date[1]) / 1000),
      };
    },
    restartDate() {
      const startDate = new Date();
      const endDate = new Date(new Date().setDate(startDate.getDate() - 1));
      return (this.date = [startDate, endDate]);
    },
    /**
     * Converts a snake_case string to camelCase.
     */
    convertSnakeToCamel(str) {
      const words = str.split("_");
      const camelCaseWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      );
      return camelCaseWords
        .join(" ")
        .replace(/ \w/g, (match) => match.toUpperCase());
    },
    formatValueAsCurrency(value, key) {
      if (key === "recoverable_revenue") {
        return formatMoney(parseFloat(value.replace(/[^0-9.-]+/g, "")), "USD");
      } else {
        return value;
      }
    },

    // fetch data for the tables
    async fetchTableData(paginatorParams, type) {
      const editsDate = this.convertDataToTimeStamp(this.date);
      let data = null;
      if (type == "recovered") {
        this.recoveredOrdersLoading = true;
        data = await this.store.getRecoveredOrdersData(
          editsDate,
          paginatorParams
        );
        data ? (this.recoveredDataTable = data) : "";
        this.recoveredOrdersLoading = false;
      } else if (type == "sent") {
        this.sentOrdersLoading = true;
        data = await this.store.getSentOrdersData(editsDate, paginatorParams);
        data ? (this.sentDataTable = data) : "";
        this.sentOrdersLoading = false;
      }
    },
    // fetch data for the all page and assign the values to the wanted components
    async fetchReportsData(parameters) {
      const editsDate = this.convertDataToTimeStamp(parameters);
      const dataOfBoxes = await this.store.getReportsData(editsDate);
      if (dataOfBoxes && dataOfBoxes.status === "failed") {
        return { status: "failed", message: dataOfBoxes.message };
      }
      if (dataOfBoxes && dataOfBoxes.recoverable_revenue) {
        formatMoney(dataOfBoxes.recoverable_revenue);
      }
      this.dataOfBoxes = dataOfBoxes;
      const sent = await this.store.getSentOrdersData(editsDate, {});
      this.sentOrdersLoading = true;
      if (sent) {
        this.sentDataTable = sent;
        this.sentOrdersLoading = false;
      }

      const recoveredData = await this.store.getRecoveredOrdersData(
        editsDate,
        {}
      );
      this.recoveredOrdersLoading = true;

      if (recoveredData) {
        this.recoveredDataTable = recoveredData;
      }
      this.recoveredOrdersLoading = false;
      return { status: "success" };
    },
    ///  change the data depend on the selected data from user
    async handleDate() {
      if (this.date) {
        const data = await this.fetchReportsData(this.date);
        if (data && data.status === "failed") {
          this.$toast.add({
            severity: "error",
            summary: "Error Message ",
            detail: data.message,
            life: 5000,
          });
        }
      }
    },
    //clear the  selected
    clearDate() {
      this.fetchReportsData(this.restartDate());
      this.date = [];
    },
  },
  mounted() {
    this.fetchReportsData(this.restartDate());
    this.$emit("routeName", "Reports");
    emitter.on("reportsDataUpdated", () => {
      this.fetchReportsData(this.restartDate());
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/helper/main";

.reports-main-wrapper {
  @include mediaQ(xsm) {
    padding: 0px;
  }
  @include mediaQ(sm) {
    padding: 0px;
  }
  @include mediaQ(md) {
    padding: 0px 30px;
  }
  .datepicker {
    margin-bottom: 20px;

    @include mediaQ(xsm) {
      width: 100%;
    }
    @include mediaQ(sm) {
      width: 100%;
    }
    @include mediaQ(md) {
      width: 50%;
    }
    @include mediaQ(xlg) {
      width: 25%;
    }
  }

  h3 {
    margin-bottom: 30px;
    padding-top: 30px;
  }
  h6 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 12px;
    padding-top: 20px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 120%;
      width: 100%;
      left: 0;
      height: 1px;
      background-color: $under-line-border;
    }
  }
  .section-wrapper {
    display: flex;
    flex-wrap: wrap;

    @include mediaQ(xsm) {
      flex-direction: column;
    }
    @include mediaQ(sm) {
      flex-direction: column;
    }
    @include mediaQ(md) {
      flex-direction: row;
    }

    width: 100%;
    gap: 20px;

    .table {
      @include mediaQ(xsm) {
        width: 100%;
      }
      @include mediaQ(sm) {
        width: 100%;
      }
      @include mediaQ(md) {
        width: calc(50% - 13px);
      }
    }
  }
}
</style>
