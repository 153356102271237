<template>
  <section class="container main-wrapper">
    <h3 class="h3-bold">Email Configuration</h3>
    <div class="config-wrapper">
      <form @submit.prevent="onSubmit" class="form">
        <div
          v-for="field in fields"
          :key="field.id"
          :class="`div${field.index} input`"
        >
          <label :for="field.id">{{ field.label }}</label>
          <component
            :is="field.component"
            v-model="field.value"
            :id="field.id"
            :type="field.type"
            :toggleMask="field.toggleMask"
            :inputStyle="field.inputStyle"
            v-if="field.component !== 'Dropdown'"
          />
          <!-- :disabled="
              field.id === 'coupon_date_value' ? isCouponDateDisabled : false
            " -->
          <template v-if="field.error">
            <p class="warning-error">{{ field.errorMessage }}</p>
          </template>
          <Dropdown
            v-model="field.value"
            :options="field.options"
            v-if="field.component === 'Dropdown'"
            showClear
            optionLabel="name"
            optionValue="value"
            :placeholder="`Select ${field.label}`"
          />
          <template v-if="field.error">
            <p class="warning-error">{{ field.errorMessage }}</p>
          </template>
          <SelectButton
            v-model="field.value"
            :options="field.options"
            v-if="field.component === 'SelectButton'"
          />
          <h3 v-if="field.index === 4" class="h3-bold coupon-cofig">
            Coupon Configuration
          </h3>
        </div>

        <div class="button-wrapper">
          <div class="loader-wrapper" v-if="loading">
            <i class="pi pi-spin pi-spinner"></i>
          </div>
          <button
            class="button-admin button"
            id="btn"
            :disabled="loading ? true : false"
          >
            Save Changes
          </button>
        </div>
      </form>
      <div class="summary-box">
        <h3 class="h3-bold">Summary</h3>
        <ul>
          <li
            v-for="(summaryItem, index) in formatCouponSummary(
              fields[0].value,
              fields[2].value,
              fields[1].value,
              fields[5].value,
              fields[4].value,
              fields[7].value,
              fields[6].value
            )"
            :key="index"
            v-html="summaryItem"
          ></li>
        </ul>
      </div>
    </div>

    <div class="card flex justify-content-center">
      <template>
        <Toast />
      </template>
    </div>
  </section>
</template>

<script>
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";

import Dropdown from "primevue/dropdown";
import SelectButton from "primevue/selectbutton";
import inputsFields from "./ConfigurationFormData.json";
import formatMoney from "../../helpers/functions/formatMoney";

import formatTimeValue from "../../helpers/functions/FormatTime";
import { emailConfiguration } from "@/stores/configuration";
import Toast from "primevue/toast";

export default {
  name: "ConfigurationForm",
  components: {
    InputNumber,
    Dropdown,
    SelectButton,
    Toast,
    InputText,
  },
  data() {
    return {
      fields: inputsFields.fields,
      formData: {},
      store: emailConfiguration(),
      message: "",
      loading: false,
      isCouponDateDisabled: false,
    };
  },
  methods: {
    //function to update the v -models who are related to every input to update it by new value when the page loaded
    updateFormData() {
      this.formData = {};
      this.fields.forEach((field) => {
        if (field.id === "type_of_coupon" && field.value === "Fixed amount") {
          this.formData[field.id] = "Fixed_amount";
        } else if (
          field.id === "type_of_coupon" &&
          field.value === "Coupon Code"
        ) {
          this.formData[field.id] = "Coupon_code";
        } else {
          this.formData[field.id] = field.value;
        }
      });
    },
    async onSubmit() {
      this.loading = true;
      this.updateFormData();
      const data = await this.store.saveNewConfiguration(this.formData);
      if (data.message && data.message === "success") {
        this.$toast.add({
          severity: "success",
          summary: "Success ",
          detail: "your Email Configuration has been updated successfully",
          life: 3000,
        });
      } else {
        const errors = data.original.data;
        this.fields.forEach((field) => {
          if (errors && errors[field.id]) {
            field.error = true;
            field.errorMessage = errors[field.id][0];
          }
        });
      }
      this.loading = false;
    },
    formatCouponSummary(
      couponValue,
      timeValue,
      timeUnit,
      discountValue,
      discountType,
      expiryValue,
      expiryUnit
    ) {
      /// sep to check if the time unit is not null or undefined  and convert it to localString to avoid expected error
      const unitString =
        timeUnit && typeof timeUnit === "string" ? timeUnit.toLowerCase() : "";
      let couponSummary;
      if (couponValue && couponValue > 1) {
        couponSummary = `A total of ${couponValue} Coupon(s) will be sent`;
      } else {
        couponSummary = `A total of ${couponValue} Coupon will be sent`;
      }

      const timeSummary = `${
        couponValue && couponValue > 1 ? "Coupon(s) " : "Coupon"
      }  will be sent After <span style="font-weight: bolder">${formatTimeValue(
        timeValue,
        unitString
      )}</span>`;
      let discountSummary;
      if (discountValue && discountType === "Percentage") {
        discountSummary = `<span style="font-weight: bolder">${discountValue}</span>% Off The cart subtotal amount`;
      } else if (discountValue && discountType === "Fixed amount") {
        discountSummary = `<span style="font-weight: bolder">${formatMoney(
          discountValue
        )}</span> Off The cart subtotal amount`;
      } else if (discountValue && discountType === "Coupon Code") {
        discountSummary = `Coupon configuration will be copied from <span style="font-weight: bolder">${discountValue}</span>`;
      } else {
        discountSummary = "the cart subtotal amount";
      }
      /// sep to check if the time unit is not null or undefined  and convert it to localString to avoid expected error
      const expireString =
        expiryUnit && typeof expiryUnit === "string"
          ? expiryUnit.toLowerCase()
          : "";
      const expirySummary = `${
        couponValue && couponValue > 1 ? "Coupon(s) " : "Coupon"
      } will expire in <span style="font-weight: bolder">${formatTimeValue(
        expiryValue,
        expireString
      )}</span>`;

      return [couponSummary, timeSummary, discountSummary, expirySummary];
    },
  },
  computed: {
    // function to execute every time the values changes in inputs to display it in the summary box
    summaryItems() {
      const couponValue = this.fields[0].value;
      const timeValue = this.fields[2].value;
      const timeUnit = this.fields[1].value;
      const discountValue = this.fields[5].value;
      const discountType = this.fields[4].value;
      const expiryValue = this.fields[7].value;
      const expiryUnit = this.fields[6].value;
      return this.formatCouponSummary(
        couponValue,
        timeValue,
        timeUnit,
        discountValue,
        discountType,
        expiryValue,
        expiryUnit
      );
    },
  },
  // watch: {
  //   fields: {
  //     handler(newValue) {
  //       // Handle the change in the value of fields[0].value]
  //       console.log(newValue[0].value);

  //       if (newValue[0].value === 1) {
  //         // If coupon_numbers is 1, disable coupon_date_value
  //         this.isCouponDateDisabled = true;
  //         newValue[2].value = 1;
  //       } else {
  //         // Otherwise, enable coupon_date_value
  //         this.isCouponDateDisabled = false;
  //       }
  //     },
  //     deep: true, // To watch for nested changes in objects or arrays
  //   },
  // },

  emits: ["routeName"],

  async mounted() {
    this.$emit("routeName", "Configuration");
    const data = await this.store.getConfiguration();
    if (data) {
      this.fields.forEach((field) => {
        field.value = data[field.id];
      });
    }
    this.updateFormData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/helper/main";
@import "@/assets/sass/helper/typography";
@import "@/assets/sass/helper/mixin";

.main-wrapper {
  padding: 36px 22px;
  background: $box-background-color;
  .bold {
    font-weight: bolder !important;
    color: red !important;
  }
  h3 {
    margin-bottom: 20px;
  }
  .coupon-cofig {
    margin-top: 30px;
  }
  .config-wrapper {
    display: flex;
    @include mediaQ(xsm) {
      flex-direction: column;
    }
    @include mediaQ(sm) {
      flex-direction: column;
    }
    @include mediaQ(md) {
      justify-content: space-between;
      flex-direction: row;
    }

    .warning-error {
      color: red;
      margin-top: 5px;
      position: absolute;
    }
    .form {
      display: flex;
      flex-direction: column;
      column-gap: 14px;
      flex-wrap: wrap;
      @include mediaQ(xsm) {
        width: 100%;
      }
      @include mediaQ(sm) {
        width: 100%;
      }
      @include mediaQ(lg) {
        flex-direction: row;
        width: 80%;
      }
      @include mediaQ(xxlg) {
        width: 70%;
      }
      .div1,
      .div2,
      .div3,
      .div5,
      .div6,
      .div7,
      .div8 {
        margin-bottom: 20px;
        @include mediaQ(xsm) {
          width: 100%;
          margin-bottom: 10px;
        }
        @include mediaQ(sm) {
          width: 100%;
          position: relative;
          margin-bottom: 10px;
        }
      }
      .div1,
      .div2,
      .div3 {
        @include mediaQ(lg) {
          width: calc(30% - 14px);
        }
      }
      .div4 {
        width: 100%;
        max-width: 100%;
        margin-top: 8px;
        label {
          display: none !important;
        }
      }
      .div5 {
        @include mediaQ(lg) {
          width: calc(70% - 14px);
        }
        @include mediaQ(xlg) {
          width: calc(70% - 14px);
        }
      }
      .div6 {
        @include mediaQ(lg) {
          width: calc(39% - 14px);
        }
        @include mediaQ(xlg) {
          width: calc(39% - 14px);
        }
      }
      .div7 {
        @include mediaQ(xlg) {
          width: calc(45% - 14px);
        }
      }
      .div8 {
        @include mediaQ(xlg) {
          width: calc(45% - 14px);
        }
      }
      .button-wrapper {
        width: calc(85% - 14px);
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        .loader-wrapper {
          padding-top: 10px;
        }
        @include mediaQ(lg) {
          width: calc(90% - 14px);
        }
      }
    }
    .summary-box {
      @include mediaQ(xsm) {
        width: 100%;
        margin-top: 30px;
      }
      @include mediaQ(sm) {
        width: 100%;
        margin-top: 30px;
      }
      @include mediaQ(md) {
        width: 30%;
        margin-top: 0px;
        align-self: flex-start;
      }

      @include mediaQ(xxlg) {
        width: 25%;
      }
      border-radius: $border-radius;
      text-shadow: $second-shadow;
      border: 1px solid $box-border-color;
      padding: 30px 10px;
      color: $text-color;
      padding: 30px 30px;
      ul {
        li {
          padding: 5px;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
    padding: 15px;
  }
}
</style>
