<template>
  <div class="container">
    <div class="middle-bar-wrapper flex-bet">
      <h4 class="h4-bold">{{ routeName }}</h4>
      <div class="coupons-buttons">
        <button class="button-admin button">
          Opened Count : {{ openedCount }}
        </button>
        <button class="button-admin button">
          Clicked Count : {{ clickedCount }}
        </button>
        <button class="button-admin button">
          Sent Coupons : {{ sentCoupon }}
        </button>
        <button class="button-admin button">
          Recovered Coupons : {{ recoveredCoupon }}
        </button>
        <button class="refresh button-admin button" @click="getLoadingData">
          <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#202223"
              d="M289.088 296.704h92.992a32 32 0 0 1 0 64H232.96a32 32 0 0 1-32-32V179.712a32 32 0 0 1 64 0v50.56a384 384 0 0 1 643.84 282.88 384 384 0 0 1-383.936 384 384 384 0 0 1-384-384h64a320 320 0 1 0 640 0 320 320 0 0 0-555.712-216.448z"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { couponCount } from "@/stores/couponCount";
import emitter from "@/service/mitt";
export default {
  name: "MiddleBar",
  data() {
    return {
      store: couponCount(),
      sentCoupon: null,
      recoveredCoupon: null,
      openedCount: null,
      clickedCount: null,
    };
  },
  props: ["routeName"],
  methods: {
    async getLoadingData() {
      const data = await this.store.getCouponsCount();
      if (data) {
        this.sentCoupon = data.Send_coupon_count;
        this.recoveredCoupon = data.recoveredCouponsCount;
        this.openedCount = data.openedCount;
        this.clickedCount = data.clickedCount;
      }
      if (this.routeName === "Reports") {
        emitter.emit("reportsDataUpdated");
      } else if (this.routeName === "Sent Orders") {
        emitter.emit("sentOrdersData");
      } else if (this.routeName === "Recovered Orders") {
        emitter.emit("recoveredOrdersData");
      }
    },
  },

  mounted() {
    this.getLoadingData();
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/helper/typography";
@import "@/assets/sass/helper/main";

.middle-bar-wrapper {
  @include flex(space-between, center);
  padding: 15px 0px;
  h4 {
    @include mediaQ(xsm) {
      display: flex;
      align-self: flex-start;
    }
    @include mediaQ(sm) {
      display: flex;
      align-self: flex-start;
    }
  }

  .coupons-buttons {
    display: flex;
    @include mediaQ(xsm) {
      margin-right: 20px;
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }
    @include mediaQ(sm) {
      margin-right: 25px;
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }
    @include mediaQ(md) {
      margin-right: 40px;
      flex-direction: row;
    }
    .refresh {
      transition: all 0.5s ease-in-out;
      @include mediaQ(xsm) {
        padding: 3px 8px;
      }
      @include mediaQ(sm) {
        padding: 6px 19px;
      }
      @include mediaQ(md) {
        padding: 8px 15px;
      }
      &:hover {
        svg path {
          fill: wheat;
        }
      }
      svg {
        width: 23px;
        transition: all 0.5s;
      }
    }
  }
}
</style>
