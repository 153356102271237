<template>
  <div v-if="showPopup" class="popup">
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPopup: false,
    };
  },
  watch: {
    showFlag(value) {
      this.showPopup = value;
    },
  },
  mounted() {
    if (this.showFlag) {
      this.showPopup = true;
    }
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    show() {
      this.showPopup = true;
    },
    hide() {
      this.showPopup = false;
      this.$emit('hide');
    },
    handleKeyDown(event) {
      if (event.key === 'Escape') {
        this.hide();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/helper/main';

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .content {
    background-color: $box-background-color;
    width: 80vw;
    height: 80vh;
    padding: 20px;
    border-radius: 20px;
    overflow: scroll;
  }
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-corner {
    width: 1px;
    height: 1px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: 1px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $border-bottom-line;
    border-radius: 10px;
  }
}
</style>
