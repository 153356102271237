import { defineStore } from 'pinia';
import { getToken } from '@/service/authLogin';
import axios from 'axios';
export const sentOrders = defineStore('sentOrders', {
  state: () => ({
    token: getToken(),
    data: null,
    route: process.env.VUE_APP_API,
  }),
  actions: {
    async getSentOrderData(parameters) {
      try {
        const response = await axios.get(`${this.route}/api/sentOrdersReport/`, {
          headers: this.token,
          params: {
            dt_params: JSON.stringify({ ...parameters }),
          },
        });

        return response.data.original;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async deleteSelectedOrders(deletedOrders) {
      console.log(deletedOrders);
      try {
        const response = await axios.delete(`${this.route}/api/deleteOrder`, {
          headers: this.token,
          params: {
            deletedOrders: [...deletedOrders],
          },
        });
        return response.data.original;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
  },
});
