//function to check the value of the field if it has a value or null
import formatMoney from './formatMoney';
export default function handelFormat(rowData, field) {
  if (rowData[field] === null) {
    return '_____________';
  } else if (field === 'total') {
    return `${formatMoney(rowData[field])}`;
  } else if (field === 'difference') {
    return `Show difference`;
  } else {
    return rowData[field];
  }
}
