<template>
  <main-table
    :TableHeaders="headerDataRef"
    @fetchData="fetchData"
    :tableInfo="tableData"
    :loading="loading"
    :sortField="'created_at'"
  >
  </main-table>
</template>

<script>
import { appLogs } from "../../stores/LogsStore";
import MainTable from "../tables/MainTable.vue";
import tableHeaders from "./logsHeader.json";

export default {
  components: { MainTable },

  data() {
    return {
      store: appLogs(),
      headerDataRef: tableHeaders,
      tableData: null,
      parameters: null,
      loading: false,
    };
  },
  mounted() {
    this.$emit("routeName", "Logs");
  },
  methods: {
    async fetchData(parameter) {
      this.loading = true;

      this.parameters = parameter;
      const data = await this.store.getLogs(this.parameters);
      if (data) {
        this.tableData = data;
      }
      if (data && data.success == false) {
        this.errorMessage = data.message;
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/helper/main";
</style>
