import { createApp } from 'vue';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import 'primeicons/primeicons.css';
import router from './Router/index';

import 'primeflex/primeflex.css';
import ToastService from 'primevue/toastservice';
import VueDatePicker from '@vuepic/vue-datepicker';
import Tooltip from 'primevue/tooltip';
// import mitt from 'mitt';

import '@vuepic/vue-datepicker/dist/main.css';

import { createPinia } from 'pinia';

// const emitter = mitt();
const pinia = createPinia();
const app = createApp(App);
app.use(PrimeVue);
// app.config.globalProperties.emitter = emitter;
app.component('VueDatePicker', VueDatePicker);
app.use(ToastService);
app.directive('tooltip', Tooltip);
app.use(router);
app.use(pinia);
app.mount('#app');
