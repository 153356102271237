import { defineStore } from 'pinia';
import { getToken } from '@/service/authLogin';
import axios from 'axios';

export const emailConfiguration = defineStore('emailConfiguration', {
  state: () => ({
    token: getToken(),
    route: process.env.VUE_APP_API,
  }),
  actions: {
    async getConfiguration() {
      try {
        const response = await axios.get(`${this.route}/api/show`, {
          headers: this.token,
        });
        return response.data[0];
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async saveNewConfiguration(newConfigValues) {
      try {
        const response = await axios.post(`${this.route}/api/store`,newConfigValues, {   
          headers: this.token,
        });
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
  },
});
