<template>
  <div class="container email-template-main-wrapper">
    <h5 class="h5-bold">Put your template code here</h5>
    <form @submit.prevent="onSubmit">
      <div class="template-wrapper">
        <div class="inputs-texts">
          <div class="input">
            <label for="Template-name">Template name</label>
            <InputText
              id="username"
              v-model="emailTemplate.name.value"
              itemid="Template-name"
              placeholder="Email Template1"
            />
            <template v-if="emailTemplate.name.error">
              <p class="warning-error">{{ emailTemplate.name.error }}</p>
            </template>
          </div>
          <div class="input">
            <label for="Template-name">Email Subject</label>
            <InputText
              id="subject"
              v-model="emailTemplate.subject.value"
              itemid="Email-Subject"
              placeholder="My template email"
            />
            <template v-if="emailTemplate.subject.error">
              <p class="warning-error">{{ emailTemplate.subject.error }}</p>
            </template>
          </div>
        </div>
        <div class="text-area">
          <div>
            <div class="header">
              <label :class="{ 'p-error': errorMessage }" for="body"
                >Email Body</label
              >
              <span>HTML & CSS only</span>
            </div>
            {{ email }}
            <Textarea
              id="body"
              v-model="emailTemplate.body.value"
              :class="{ 'p-invalid': errorMessage }"
              aria-describedby="text-error"
              @input="onBodyInput"
            />
            <template v-if="emailTemplate.body.error">
              <p class="warning-error">{{ emailTemplate.name.error }}</p>
            </template>
            <div v-if="errorMessage" id="text-error" class="p-error">
              {{ errorMessage }}
            </div>
          </div>
        </div>
        <div class="inputs-wrapper">
          <div class="loader-wrapper" v-if="loading">
            <i class="pi pi-spin pi-spinner"></i>
          </div>
          <button
            class="button button-admin"
            @click.prevent="resetTemplate"
            :disabled="loading ? true : false"
          >
            reset
          </button>
          <!-- <button class="preview button-admin" @click.prevent="previewEmail">
            Preview
          </button> -->
          <button
            class="button button-admin"
            @click.prevent="saveEmail"
            :disabled="loading ? true : false"
          >
            Save
          </button>
        </div>
      </div>
    </form>
    <pop-up :showFlag="showPopup" @hide="handelHide">
      <div class="popup-header">
        <h4 class="h5-bold">
          Email Subject : {{ emailTemplate.subject.value }}
        </h4>
        <h4 class="h5-bold">Template Name : {{ emailTemplate.name.value }}</h4>
        <close-button @click="showPopup = false" />
      </div>
      <iframe :srcdoc="iframeSrcdoc" class="iframe"></iframe>
    </pop-up>
  </div>
  <template>
    <Toast />
  </template>
</template>

<script>
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import { emailTemplate } from "@/stores/emailTemplateStore";
import Toast from "primevue/toast";
import PopUp from "../ui/PopUp.vue";
import CloseButton from "../ui/CloseButton.vue";

export default {
  name: "EmailTemplate",
  components: {
    InputText,
    Textarea,
    Toast,
    PopUp,
    CloseButton,
  },
  data() {
    return {
      emailTemplate: {
        body: { value: "", error: "", id: "mail_template" },
        name: { value: "", error: "", id: "template_name" },
        subject: { value: "", error: "", id: "email_subject" },
      },
      errorMessage: "",
      showPopup: false,
      store: emailTemplate(),
      successSave: null,
      loading: false,
    };
  },
  computed: {
    // handel  any change happen in the email template to reflect on the screen
    iframeSrcdoc() {
      return this.emailTemplate.body.value;
    },
  },
  emits: ["routeName"],
  async mounted() {
    window.addEventListener("keydown", this.handleKeyDown);
    const data = await this.store.showDefaultEmailTemplate();
    this.handelResponseFormat(data);
  },

  methods: {
    onBodyInput(event) {
      this.parameters = event.target.value;
    },

    // match the retrieved data from the backend to assign it to the view
    handelResponseFormat(data) {
      if (data) {
        this.emailTemplate.body.value = data.mail_template;
        this.emailTemplate.name.value = data.template_name;
        this.emailTemplate.subject.value = data.email_subject;
      }
    },
    //preview the email
    async previewEmail() {
      if (this.emailTemplate.body.value.trim().length === 0) {
        this.errorMessage = "Please enter a valid email template";
      } else {
        this.errorMessage = "";
        const data = await this.store.getEmailPreview(
          this.emailTemplate.body.value
        );
        if (data) {
          this.emailTemplate.body.value = data.data;
          this.showPopup = true;
        }
      }
    },
    // save to backend
    async saveEmail() {
      const formatData = {
        mail_template: this.emailTemplate.body.value,
        template_name: this.emailTemplate.name.value,
        email_subject: this.emailTemplate.subject.value,
      };
      this.loading = true;
      const data = await this.store.saveNewEmailTemplate(formatData);
      data ? (this.successSave = data.status) : false;

      if (this.successSave === "success") {
        this.$toast.add({
          severity: "success",
          summary: "Success ",
          detail: "your Email Template has been updated successfully",
          life: 3000,
        });
      } else {
        const errors = data.errors;
        Object.keys(this.emailTemplate).forEach((key) => {
          const field = this.emailTemplate[key];
          if (errors && errors[field.id]) {
            field.error = errors[field.id];
          }
        });
      }
      this.loading = false;
    },
    //close showup
    handelHide() {
      this.showPopup = false;
    },
    //reset the email template to the default
    async resetTemplate() {
      this.loading = true;
      const data = await this.store.resetEmailTemplate();
      data ? this.handelResponseFormat(data) : "";
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/helper/main";
@import "@/assets/sass/helper/typography";

.email-template-main-wrapper {
  background: $box-background-color;
  padding: 20px 25px;
  h5 {
    margin-bottom: 15px;
  }
  .template-wrapper {
    width: 100%;
    flex-wrap: nowrap;
    .inputs-texts {
      @include mediaQ(xsm) {
        width: 100%;
      }
      @include mediaQ(sm) {
        width: 100%;
      }
      @include mediaQ(md) {
        width: 65%;
      }
      @include mediaQ(xlg) {
        width: 50%;
      }
      margin-bottom: 20px;
      display: flex;
      gap: 14px;
      div {
        width: calc(50% - 14px);
        @include mediaQ(xsm) {
          width: 100%;
        }
        @include mediaQ(sm) {
          width: 100%;
        }
      }
    }

    .warning-error {
      color: red;
      margin-top: 5px;
    }
    .text-area {
      width: 100%;
      margin-bottom: 20px;
      textarea {
        width: 100%;
        height: 586px;
        border-radius: $border-radius;
      }
      .header {
        margin-bottom: 4px;
        display: flex;
        justify-content: space-between;
        span {
          color: $interactive;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
    .inputs-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      :first-child {
        margin-right: 5px;
      }
      .preview {
        background-color: $white;
        border: 1px solid $button-border-color;

        padding: 13px 15px;
        @include mediaQ(xsm) {
          padding: 6px 8px;
        }
        @include mediaQ(sm) {
          padding: 6px 8px;
        }
        @include mediaQ(md) {
          padding: 13px 15px;
          margin-right: 8px;
        }
        border-radius: $border-radius;
        cursor: pointer;
      }
    }
  }

  iframe {
    width: 100%;
    height: calc(100% - 80px);
    border-radius: 20px;
    padding: 20px;
    border: 1px solid skyblue;
  }
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    h4 {
      margin: 0;
    }
  }
}
</style>
