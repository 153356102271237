import { defineStore } from 'pinia';
import { getToken } from '@/service/authLogin';
import axios from 'axios';

export const reportsData = defineStore('reportsData', {
  state: () => ({
    token: getToken(),
    data: null,
    route: process.env.VUE_APP_API,
    update: false,
  }),
  actions: {
    async getReportsData(timeRange = {}) {
      try {
        const response = await axios.get(`${this.route}/api/count`, {
          headers: this.token,
          params: timeRange,
        });
        return response.data.original;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async getSentOrdersData(timeRange = {}, paginator = {}) {
      try {
        const response = await axios.get(`${this.route}/api/RecoverableOrders`, {
          headers: this.token,
          params: { ...timeRange, dt_params: JSON.stringify({ ...paginator }) },
        });

        return response.data.original;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async getRecoveredOrdersData(timeRange = {}, paginator = {}) {
      try {
        const response = await axios.get(`${this.route}/api/RecoveredOrders`, {
          headers: this.token,
          params: { ...timeRange, dt_params: JSON.stringify({ ...paginator }) },
        });
        return response.data.original;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
  },
});
