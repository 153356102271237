<template>
  <div class="card container main-table">
    <template v-if="selectedProduct.length">
      <div class="delete-nav-wrapper">
        <button @click="deletedSelectedOrders">
          {{
            selectedProduct.length === tableData.length
              ? "Delete all"
              : "Delete"
          }}
        </button>
        <span>{{ selectedProduct.length }} Selected</span>
      </div>
    </template>
    <DataTable
      :value="tableData"
      v-model:selection="selectedProduct"
      tableStyle="min-width: 50rem"
      responsiveLayout="scroll"
      breakpoint="1200px"
      @page="onPage($event)"
      @sort="onPage($event)"
      @select="onPage($event)"
      :totalRecords="totalRecords"
      removableSort
      :lazy="true"
      :loading="loading"
      paginator
      :rows="rows"
      :rowsPerPageOptions="[5, 10, 20, 50]"
      paginatorTemplate=" FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
      currentPageReportTemplate="{first}  of {totalRecords} items"
    >
      <template v-for="(col, i) in TableHeaders" :key="i">
        <template v-if="col.select">
          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
        </template>

        <template v-else-if="col.field === 'clicked'">
          <Column
            :field="col.field"
            :header="col.name"
            :headerStyle="{ width: col.style }"
          >
            <template #body="slotProps">
              <span :style="handelColor(slotProps.data.clicked, true)">{{
                handelYesOrNo(slotProps.data.clicked, false)
              }}</span>
            </template>
          </Column>
        </template>
        <template v-else-if="col.field === 'opened'">
          <Column
            :field="col.field"
            :header="col.name"
            :headerStyle="{ width: col.style }"
          >
            <template #body="slotProps">
              <span :style="handelColor(slotProps.data.opened, true)">{{
                handelYesOrNo(slotProps.data.opened, false)
              }}</span>
            </template>
          </Column>
        </template>
        <template v-else-if="col.field === 'difference'">
          <Column
            :field="col.field"
            :header="col.name"
            :sortable="col.storable"
            :headerStyle="{ width: col.style }"
          >
            <template #body="slotProps">
              <span style="text-align: center">
                <template v-if="handelFormat(slotProps.data, col.field)">
                  <a href="#" @click.prevent="onShowDifference(slotProps.data)"
                    >Show Difference</a
                  >
                </template>
              </span>
            </template>
          </Column>
        </template>
        <template v-else>
          <Column
            :field="col.field"
            :header="col.name"
            :sortable="col.storable"
            :headerStyle="{ width: col.style }"
          >
            <template #body="slotProps">
              <span
                style="text-align: center"
                v-html="handelFormat(slotProps.data, col.field)"
              ></span>
            </template>
          </Column>
        </template>
      </template>
      <template #empty>
        <div class="not-found">
          <img src="@/assets/images/icons/magnifc.svg" alt="not found orders" />
          <h3 class="h3-bold">No Orders Found</h3>
        </div>
      </template>
      <template #loading>
        <i class="pi pi-spin pi-spinner spinner-icon"></i
      ></template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import handelDate from "../../helpers/functions/formatDate";
import handelFormat from "../../helpers/functions/handelFormat";
import { recoveredOrdersData } from "@/stores/recoveredOrdersStore";

export default {
  name: "MainTable",
  props: {
    tableInfo: {
      type: Object,
      default: () => Object,
    },
    TableHeaders: {
      type: Array,
    },
    errorMessage: String,
    loading: Boolean,
    sortField: String,
  },
  components: {
    DataTable,
    Column,
  },
  data() {
    return {
      tableData: [],
      status: null,
      loadingTable: false,
      selectedProduct: [],
      deletedOrders: [],
      metaKey: true,
      totalRecords: 0,
      rows: 10,
      store: recoveredOrdersData(),
    };
  },

  mounted() {
    if (this.sortField) {
      this.$emit("fetchData", {
        originalEvent: {
          page: 1,
          first: 10,
          rows: 10,
          pageCount: 2,
        },
        first: 10,
        rows: 10,
        sortField: this.sortField,
        sortOrder: 1,
        pageCount: 1,
        page: 1,
      });
    } else {
      this.$emit("fetchData", {});
    }
  },
  watch: {
    tableInfo: {
      // watch the change of the table info to assign the value to table to render it
      handler(newValue) {
        if (!newValue) {
          return;
        }
        const { success, payload } = newValue;
        this.status = success;
        if (success) {
          this.tableData = payload.data;
          this.totalRecords = payload.total;
        }
      },
      immediate: true,
    },
    selectedProduct: {
      handler(newValue) {
        //assign the  selected orders  to remove them from tha table
        const invoiceIds = newValue.map((obj) => obj.order_id);
        this.deletedOrders = [invoiceIds];
      },
    },
  },

  methods: {
    onPage(parameters) {
      if (this.sortField) {
        const newParameters = { ...parameters };
        newParameters.sortField = this.sortField;
        newParameters.sortOrder = -1;

        this.$emit("fetchData", newParameters);
      } else {
        this.$emit("fetchData", parameters);
      }
    },
    //remove the selected orders handler
    deletedSelectedOrders() {
      this.$emit("handelSelected", this.deletedOrders);
      this.selectedProduct = [];
    },

    handelDate(date) {
      return handelDate(date);
    },
    handelYesOrNo(value) {
      if (value === 0) {
        return "NO";
      } else if (value === 1) {
        return "YES";
      }
    },
    handelColor(value, color) {
      if (color && value === 0) {
        return {
          background: "#f8d7da",
          padding: "5px 10px",
          borderRadius: "5px",
          color: "black",
        };
      } else {
        return {
          background: "#d1e7dd",
          padding: "5px 10px",
          borderRadius: "5px",
          color: "black",
        };
      }
    },
    handelFormat(data, field) {
      return handelFormat(data, field);
    },
    //handel the difference between the orders
    onShowDifference(selectedOrder) {
      this.$emit("handelDifference", selectedOrder);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/helper/main";
.main-table {
  position: relative;

  .delete-nav-wrapper {
    height: 50px;
    background: $nav-color;
    padding: 8px 30px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 1000;
    button {
      background: $box-background-color;
      color: red;
      padding: 10px 20px;
      outline: $box-background-color;
      border: 1px solid $box-background-color;
      border-radius: $border-radius;
      margin: 0px 25px;
      cursor: pointer;
    }
    span {
      color: $box-background-color;
    }
  }
  :deep(.p-checkbox .p-checkbox-box) {
    position: absolute;
    z-index: 1000;
  }
}
.spinner-icon {
  font-size: 5rem;
  color: white;
}
.not-found {
  width: 100%;
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin-bottom: 10px;
  }
}
</style>
