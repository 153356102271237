<template>
  <div class="upperBar">
    <div class="container">
      <div class="row">
        <router-link to="/Reports">
          <div class="title-upper">
            <img
              src="@/assets/images/icons/AppImage.svg"
              alt="App Image"
              class="app-logo"
            />

            <h4>{{ appName }}</h4>
          </div>
        </router-link>
        <div class="left-space">
          <button>
            <img src="@/assets/images/icons/Frame.svg" alt="frame" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "upperBar",
  methods: {},
  props: { appName: String },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.upperBar {
  margin-top: 25px;
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 10px 10px;
    background: white;
  }

  .title-upper {
    display: flex;
    align-items: center;
    gap: 10px;

    h4 {
      color: #202223;
      font-weight: 300;
      font-size: 16px;
    }
    .app-logo {
      width: 34px;
      height: 34px;
      margin-right: 7px;
    }
  }
  .left-space {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: flex-end;
    margin-right: 7px;
    button {
      background: rgba(255, 255, 255, 0);
      border: none;
      cursor: pointer;
    }
  }
  h4 {
    padding: 0;
    margin: 0;
  }
}
</style>
