<template>
  <main-table
    :TableHeaders="headerDataRef"
    :tableInfo="recoveredTablaData"
    @fetchData="fetchData"
    :errorMessage="errorMessage"
    :loading="loading"
    @handelDifference="handelDifference"
  />
  <pop-up
    :showFlag="showPopup"
    @hide="handelHide"
    class="show-difference-wrapper"
  >
    <div class="header">
      <close-button @click="showPopup = false" />
    </div>
    <div class="tables-wrapper">
      <h3>
        the Coupon : <span>{{ couponValue }}</span>
      </h3>
      <div>
        <static-table
          :loading="ordersLoading"
          :headers="beforeHeaders"
          :tableInfo="beforeData"
          titleTable="products before"
          :info="false"
          class="table"
        />
      </div>
      <div>
        <static-table
          :loading="ordersLoading"
          :headers="afterHeaders"
          :tableInfo="afterData"
          class="table"
          titleTable="products after"
        />
      </div>
    </div>
  </pop-up>
</template>

<script>
import headerData from "./recoveredOrderHeader.json";
import MainTable from "../tables/MainTable.vue";
import { recoveredOrdersData } from "@/stores/recoveredOrdersStore";
import afterHeaders from "./afterHeaders.json";
import beforeHeaders from "./beforeHeaders.json";
import PopUp from "../ui/PopUp.vue";
import CloseButton from "../ui/CloseButton.vue";
import StaticTable from "../tables/StaticTable.vue";
import emitter from "@/service/mitt";

export default {
  components: { MainTable, PopUp, CloseButton, StaticTable },
  data() {
    return {
      store: recoveredOrdersData(),
      headerDataRef: [...headerData],
      recoveredTablaData: null,
      parameters: {},
      errorMessage: "",
      loading: false,
      showPopup: false,
      afterData: null,
      beforeData: null,
      couponValue: null,
      afterHeaders: afterHeaders,
      beforeHeaders: beforeHeaders,
    };
  },
  emits: ["routeName"],

  methods: {
    // fetch the table data
    async fetchData(parameter) {
      this.loading = true;
      this.parameters = parameter;
      const data = await this.store.getReportsData(this.parameters);
      if (data) {
        this.recoveredTablaData = data;
        this.loading = false;
      }
      if (data && data.success == false) {
        this.errorMessage = data.message;
      }
    },

    // show the difference between the product before using the coupon and after it
    async handelDifference(params) {
      this.loading = true;
      const { code, email } = params;
      const response = await this.store.getDifference(email, code);
      if (response) {
        const { coupon, data } = response;
        const { data_before, data_after } = data;
        data_before ? (this.beforeData = data_before) : "";
        data_after ? (this.afterData = data_after) : "";
        coupon ? (this.couponValue = coupon) : "";
        console.log(this.afterData, this.beforeData, coupon);
        this.loading = false;
        this.showPopup = true;
      }
    },
    //close popUp
    handelHide() {
      this.showPopup = false;
    },
  },
  mounted() {
    this.$emit("routeName", "Recovered Orders");
    window.addEventListener("keydown", this.handleKeyDown);
    emitter.on("recoveredOrdersData", () => {
      this.fetchData();
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/helper/main";

.show-difference-wrapper {
  padding: 2rem;
  .header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .tables-wrapper {
    h3 {
      span {
        color: red;
      }
    }
    div {
      .table {
        @include mediaQ(xsm) {
          width: 100%;
        }
        @include mediaQ(sm) {
          width: 100%;
        }
      }
    }
  }
}
</style>
