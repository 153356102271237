<template>
  <tooltip-wrapper>
    <template #content>
      <span type="text" placeholder="Bottom">
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20.251 17.913C19.9194 18.4827 19.3246 18.8228 18.6607 18.8228H2.33924C1.67533 18.8228 1.08092 18.4827 0.748965 17.913C0.417012 17.3433 0.417012 16.6629 0.748965 16.0932L8.90971 2.08565C9.24166 1.51596 9.83608 1.17578 10.5 1.17578C11.1639 1.17578 11.7583 1.51596 12.0903 2.08565L20.251 16.0932C20.583 16.6629 20.583 17.3433 20.251 17.913Z"
            fill="#FFC453"
          />
          <path
            d="M11.6118 6.64571V10.5054C11.6118 11.3248 10.9887 11.9913 10.2229 11.9913C9.45706 11.9913 8.83398 11.3248 8.83398 10.5054V6.64571C8.83398 5.82659 9.45706 5.16016 10.2229 5.16016C10.9887 5.16016 11.6118 5.82659 11.6118 6.64571Z"
            fill="white"
          />
          <path
            d="M11.6118 15.6907C11.6118 16.4754 10.9887 17.1139 10.2229 17.1139C9.45706 17.1139 8.83398 16.4754 8.83398 15.6907C8.83398 14.906 9.45706 14.2676 10.2229 14.2676C10.9887 14.2676 11.6118 14.906 11.6118 15.6907Z"
            fill="white"
          />
        </svg>
      </span>
    </template>
    <slot name="message">{{ message }}</slot>
  </tooltip-wrapper>
</template>
<script>

import TooltipWrapper from  "../ui/TooltipWrapper"
export default {
  components: { TooltipWrapper },
  props: {
    message: {
      type: String,
    },
  },
};
</script>
