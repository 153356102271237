import { defineStore } from 'pinia';
import { getToken } from '@/service/authLogin';
import axios from 'axios';

export const appLogs = defineStore('Logs', {
  state: () => ({
    token: getToken(),
    route: process.env.VUE_APP_API,
  }),
  actions: {
    async getLogs(parameters) {
      try {
        const response = await axios.get(`${this.route}/api/logs/`, {
          headers: this.token,
          params: {
            dt_params: JSON.stringify({ ...parameters }),
          },
        });
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
  },
});
