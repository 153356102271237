<template>
  <section class="stats-card">
    <div class="stats-card-body">
      <div v-show="loadingRequest" class="statices-loader">
        <i class="pi pi-spin pi-spinner"></i>
      </div>
      <h2 v-if="boxCounter != undefined">{{ boxCounter }}</h2>
      <p>{{ boxContent ? boxContent : "" }}</p>
    </div>
    <template v-if="errors">
      <div class="not-found">
        <div class="img-wrapper">
          <img src="@/assets/images/icons/magnifc.svg" alt="not found orders" />
        </div>
        <p>{{ errors }}</p>
      </div>
    </template>
  </section>
</template>

<script>
export default {
  name: "BoxOfStatistic",
  props: {
    boxCounter: {
      type: [String, Number],
    },
    boxContent: String,
    loadingRequest: {
      type: Boolean,
    },
    errors: String,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/helper/main";

.stats-card {
  min-height: 144px;
  @include mediaQ(xsm) {
    width: 100%;
  }
  @include mediaQ(sm) {
    width: 100%;
  }
  @include mediaQ(md) {
    width: calc(50% - 16px);
  }
  @include mediaQ(lg) {
    width: calc(33% - 16px);
  }
  position: relative;
  border-radius: $border-radius;
  background-color: $box-background-color;
  border: 1px solid $box-border-color;
  display: flex;
  gap: 20px;
  .statices-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: white;
      font-size: 50px;
    }
  }
  .stats-card-body {
    padding: 60px 20px 20px 25px;
    h2 {
      font-weight: 500;
      font-size: 28px;
      line-height: 40px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $bx-content-color;
    }
  }
  .not-found {
    width: 100%;
    margin-right: 50px;
    @include flex(center, center, column, unset, unset);
    .img-wrapper {
      height: 69px;
      width: 69px;
      margin-bottom: 5px;

      img {
        width: 100%;
      }
    }
    p {
      font-size: 14px;
      line-height: 24px;
    }
  }
}
</style>
