import { defineStore } from 'pinia';
import { getToken } from '@/service/authLogin';
import axios from 'axios';

export const couponCount = defineStore('couponCount', {
  state: () => ({
    token: getToken(),
    route: process.env.VUE_APP_API,
  }),
  actions: {
    async getCouponsCount() {
      try {
        const response = await axios.get(`${this.route}/api/counter/`, {
          headers: this.token,
        });
        return response.data.original;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
  },
});
