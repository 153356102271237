<template>
  <main-table
    :TableHeaders="headerDataRef"
    :tableInfo="sentTableData"
    @fetchData="fetchData"
    :errorMessage="errorMessage"
    @handelSelected="handelSelected"
    :loading="loading"
  />
</template>

<script>
import headerData from "./sentOrders.json";
import MainTable from "../tables/MainTable.vue";
import { sentOrders } from "../../stores/sentOrders";
import emitter from "@/service/mitt";

export default {
  components: { MainTable },
  data() {
    return {
      store: sentOrders(),
      headerDataRef: [...headerData],
      sentTableData: null,
      parameters: {},
      errorMessage: "",
      loading: false,
    };
  },
  emits: ["routeName"],

  methods: {
    // fetch the table data
    async fetchData(parameter) {
      this.loading = true;
      this.parameters = parameter;
      const data = await this.store.getSentOrderData(this.parameters);
      if (data) {
        this.sentTableData = data;
        this.loading = false;
      }

      if (data && data.success == false) {
        this.errorMessage = data.message;
      }
    },
    //delete the selected data to remove it from the screen and backend
    async handelSelected(params) {
      if (params) {
        const { message } = await this.store.deleteSelectedOrders(params);
        if (message && message === "Orders deleted successfully") {
          this.fetchData(this.parameters);
        } else {
          return;
        }
      }
    },
  },
  mounted() {
    this.$emit("routeName", "Sent Orders");
    emitter.on("sentOrdersData", () => {
      this.fetchData();
    });
  },
};
</script>

<style></style>
