<template>
  <section class="table-statistics">
    <h3>{{ titleTable }}</h3>
    <DataTable
      class="dashboard-table static-table"
      :class="{ customPadding: info }"
      :paginator="true"
      :lazy="true"
      :loading="loading"
      @page="onPage($event)"
      :value="tableData"
      :rows="5"
      scrollable
      scrollHeight="15rem"
      :tableStyle="info ? 'height: 15rem' : 'height: 5rem'"
      :totalRecords="totalRecords"
      :paginatorTemplate="
        info ? 'CurrentPageReport PrevPageLink PageLinks NextPageLink' : ''
      "
      responsiveLayout="scroll"
      :currentPageReportTemplate="info ? '{first} of {totalRecords} items' : ''"
    >
      <template v-for="(col, i) in headers" :key="i">
        <template>
          <Column
            :field="col.field"
            :header="col.name"
            :sortable="col.storable"
          >
            <template #body="slotProps">
              <span style="text-align: center">{{
                handelFormat(slotProps.data, col.field)
              }}</span>
            </template></Column
          >
        </template>
      </template>
      <template #empty>
        <div class="not-found">
          <img src="@/assets/images/icons/magnifc.svg" alt="not found orders" />
          <h3 class="h3-bold">No Orders Found</h3>
        </div>
      </template>
      <template #loading>
        <i class="pi pi-spin pi-spinner spinner-icon"></i
      ></template>
    </DataTable>
  </section>
</template>

<script>
import DataTable from "primevue/datatable";
import handelDate from "../../helpers/functions/formatDate";
import handelFormat from "../../helpers/functions/handelFormat";

import Column from "primevue/column";
export default {
  name: "StatisticsTable",
  components: {
    DataTable,
    Column,
  },
  props: {
    titleTable: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
    },
    tableInfo: {
      type: Object,
      default: () => Object,
    },
    info: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      tableData: null,
      status: null,
      totalRecords: 0,
      loadingTable: false,
    };
  },

  mounted() {},

  watch: {
    tableInfo: {
      // watch the change of the table info to assign the value to table to render it
      handler(newValue) {
        if (!newValue) {
          return;
        }
        const { success, payload } = newValue;
        this.status = success;
        if (success) {
          this.tableData = payload.data;
          this.totalRecords = payload.total;
        }
      },
      immediate: true,
    },
  },

  methods: {
    onPage(parameters) {
      if (this.titleTable === "Sent Orders") {
        this.$emit("fetchTableData", parameters, "sent");
      } else if (this.titleTable === "Recovered Orders") {
        this.$emit("fetchTableData", parameters, "recovered");
      }
    },
    handelDate(date) {
      return handelDate(date);
    },
    handelFormat(data, field) {
      return handelFormat(data, field);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/helper/main";
.table-statistics {
  @include mediaQ(xsm) {
    width: 100%;
  }
  @include mediaQ(sm) {
    width: 100%;
  }
  @include mediaQ(md) {
    width: calc(50% - 13px);
  }
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: 1px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $border-bottom-line;
    border-radius: 10px;
  }
  .not-found {
    width: 100%;
    min-height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      margin-bottom: 10px;
    }
  }
  h3 {
    margin-bottom: 17px;
    padding-top: 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }

  .dashboard-table {
    overflow: scroll;
    border-radius: $border-radius;
  }
  .reportsHeight {
    height: 296px !important;
  }
}
.image-wrapper {
  img {
    width: 150px;
    height: 80px;
  }
}
</style>
